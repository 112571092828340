import React, {useState, useEffect } from 'react';


class PubSubClient {
    constructor() {
        this.socket = undefined;
        this.callbacks = {};
        this.connectionInterval = undefined;
        this.clientId = "WebClient" + Math.random().toString(36).substring(7);
        this.sendConnectionsUpdate = this.sendConnectionsUpdate.bind(this);
    }

    sendConnectionsUpdate(){
        if (this.socket !== undefined && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({Type: 1, Value: this.clientId, Channel: "connections"}));
        }
    }

    onOpen(){
        console.log("Connection opened")
        this.connectionInterval = setInterval(this.sendConnectionsUpdate, 1000);
    }

    onMessage(event){
        var res = JSON.parse(event.data);
        if (this.callbacks[res.Channel] && res.Type == 1) {
            this.callbacks[res.Channel](res.Value);
        }
    }

    onClose(event){
        console.log("Connection closed")
        console.log(event)
        clearInterval(this.connectionInterval);
    }

    begin(){
        this.socket = new WebSocket("ws://192.168.50.24:9001");
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);
        this.socket.onclose = this.onClose.bind(this);
    }

    isConnected() {
        return this.socket !== undefined && this.socket.readyState === WebSocket.OPEN;
    }

    subscribe(topic, callback) {
        this.callbacks[topic] = callback;
        this.socket.send(JSON.stringify({Type: 2, Value: "hi", Channel: topic}))
    }

    unsubscribe(topic){
        this.callbacks[topic] = undefined;
    }

    close(){
        this.socket.close();
    }

    send(channel, value){
    
        if (this.isConnected()){
            console.log(channel);
            console.log(value);
            this.socket.send(JSON.stringify({Type: 1, Value: value, Channel: channel}));
        }
    }
}

var client = new PubSubClient();
export default function PubSubConnection(props){
    useEffect(()=>{
        client.begin();
        return () => {
            console.log("callback")
            client.close();
        }
    })
    
    const childrenWithProps = React.Children.map(props.children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
        return React.cloneElement(child, { client: client });
    }
    return child;
      });
    return (
        <React.Fragment>
            {childrenWithProps}
        </React.Fragment>
    )
}