import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PageLinkGroup from './PageLinkGroup';
import PageLink from './PageLink';
import AllLightsCommandConfig from './AllLightsCommandConfig';
import ConnectionsList from "./ConnectionsList";
import PianoControl from "./PianoControl";
import MainLights from "./MainLights";

export default function Controller(props) {
    console.log(props)
  return (
        <Router>
            <Switch>
            <Route path="/iot">
                <IoTMenu client={props.client}/>
            </Route>
            <Route path="/direct-light">
                <AllLightsCommandConfig client={props.client}/>
            </Route>
            <Route path="/piano-control">
                <PianoControl client={props.client}/>
            </Route>
            <Route path="/connections">
                <ConnectionsList client={props.client}/>
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/linkedin" component={()=>{
                window.location.href = "https://www.linkedin.com/in/john-laboe/"
                return null
            }} />
            <Route path="/main-lights">
                    <MainLights client={props.client} />
            </Route>
                
            <Route path="/">
                <Home client={props.client} />
            </Route>
            </Switch>
        </Router>
  );
}

function Home(props) {
  const [isConnected, setIsConnected] = useState(false);
  const checkAndUpdate = function(){
      var currentConnect = props.client.isConnected();
      if (currentConnect !== isConnected){
          setIsConnected(currentConnect)
      }
  }
  useEffect(()=>{
      setInterval(checkAndUpdate, 1000)
  },[])
  return (
    <React.Fragment>
        <PageLinkGroup>
              
            <PageLink to="/about">
                About
            </PageLink>
            <PageLink to="linkedin">
                LinkedIn
            </PageLink>
              
            {
                isConnected &&
                <PageLink to="/iot">
                    Control Center
                </PageLink>
            }
              
          </PageLinkGroup>
      </React.Fragment>
  
  );
}

function IoTMenu(props) {
    return (
        <React.Fragment>
            <PageLinkGroup>
                <PageLink to="/direct-light">
                    Kitchen Light Direct
                </PageLink>
                <PageLink to="/piano-control">
                    Piano Control
                </PageLink>
                <PageLink to="/connections">
                    Connections List
                </PageLink>
                <PageLink to="/main-lights">
                    Main Lights
                </PageLink>
                <PageLink to="/">
                    Return
                </PageLink>
                
            </PageLinkGroup>
        </React.Fragment>
    
    );
}

function About() {
  return <h2>About</h2>;
}