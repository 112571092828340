import React, {useState, useEffect } from 'react';
import PageLink from './PageLink';
import PageLinkGroup from './PageLinkGroup';

var connectionsList = {};
var updateInterval;
export default function ConnectionsList(props) {
    const [cacheBuster, setCacheBuster] = useState(0);
    
    useEffect(()=>{
        var cleanOldConnections = function(){
            var curTime = Date.now();
            for (const [key, value] of Object.entries(connectionsList)) {
                 if (curTime - value > 10000) {
                     delete connectionsList[key];
                     setCacheBuster(Math.random());
                 }
              }
        }
        
        updateInterval = setInterval(cleanOldConnections, 10000);
        var updateConnection = function(message) {
            var shouldUpdate = true;
            console.log(connectionsList);
            if (message in connectionsList){
                shouldUpdate = false;
            }
            
            connectionsList[message] = Date.now();
            if (shouldUpdate) {
                console.log("here")
                setCacheBuster(Math.random());
            }  
        }
        setTimeout(()=>{
            if (props.client.isConnected()){
                props.client.subscribe("connections", updateConnection);
            }
           
        }, 1000);
        return function cleanup() {
            clearInterval(updateInterval);
            props.client.unsubscribe("connections");
        }

    },[])
    return (
        <React.Fragment>
            
            <PageLinkGroup>
                <PageLink to="/iot">
                    Return
                </PageLink>
            </PageLinkGroup>
            {
                Object.keys(connectionsList).map((client)=>{
                    return (
                        <p>{client}</p>
                    )
                    
                })
            }
            
        </React.Fragment>
        
    )
}