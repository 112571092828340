import React, {useState, useEffect } from 'react';
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import PageLink from './PageLink';
import PageLinkGroup from './PageLinkGroup';

export default function AllLightsCommandConfig(props) {
    const [color, setColor] = useColor("hex", "#121212");
    const [delay, setDelay] = useState(0);
    const setColorAndSend = function(e) {
        console.log(e)
        props.client.send("klall", e.hex.substring(1).toUpperCase());
        props.client.send("slrall", e.hex.substring(1).toUpperCase());
        props.client.send("sllall", e.hex.substring(1).toUpperCase());
        setColor(e)
    }
    const turnOff = function() {
        props.client.send("klall", "000000");
        setColor(toColor("hex", "#000000"));
    }
    return (
        <React.Fragment>
            <div className="command">
                <input hidden name="type" value="all" />
                <input hidden name="color" value={color.hex.substring(1)} />
                <input hidden name="delay" value={delay} />
                <ColorPicker hideHEX hideRGB  width={Math.min(970, window.innerWidth * 0.97)} height={Math.min(550, window.innerHeight * 0.55)} color={color} onChange={setColorAndSend} dark />
            </div>
            <PageLinkGroup>
                <PageLink onClick={turnOff}>
                    Turn Off
                </PageLink>
                <PageLink to="/iot">
                    Return
                </PageLink>
            </PageLinkGroup>
            
        </React.Fragment>
        
    )
}