import logo from './logo.svg';
import { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import Button from '@material-ui/core/Button';
import Controller from './Controller';
import './App.css';
import PubSubConnection from './PubSubConnection';

var socket;
var fullConnections = new Set();
function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <PubSubConnection>
          <Controller />
        </PubSubConnection>
        
        
      </header>
    </div>
  );
}

export default App;
