import React from "react";
import {
    Link
  } from "react-router-dom";

export default function PageLink(props) {
    if (props.disabled) {
        return (
            <div className="page-link-div disabled">
                {props.children}
            </div>  
        )
    }
    if (props.onClick){
        return (
            <a onClick={props.onClick} >
                <div className="page-link-div">
                    {props.children}
                </div>  
            </a>
        )
    }
    return (
        <Link to={props.to} >
            <div className="page-link-div">
                {props.children}
            </div>  
        </Link>
    )
}