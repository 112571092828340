import React, {useState, useEffect } from 'react';
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import PageLink from './PageLink';
import PageLinkGroup from './PageLinkGroup';

export default function MainLights(props) {
    const [lampIsOn, setLampIsOn] = useState(false);
    const [mainIsOn, setMainIsOn] = useState(false);
   
    const turnOffLamp = function() {
        props.client.send("lamplight", "off");
        setLampIsOn(false)
    }

    const turnOffMain = function() {
        props.client.send("mainlight", "off");
        setMainIsOn(false)
    }

    const turnOnMain = function() {
        props.client.send("mainlight", "on");
        setMainIsOn(true)
    }

    const turnOnLamp = function() {
        props.client.send("lamplight", "on");
        setLampIsOn(true)
    }


    const computerOff = function() {
        props.client.send("pcpower", "off")
    }

    useEffect(()=>{
        
        var updateLamp = function(message) {
            console.log(message)
        }

        var updateMain = function(message) { 
            console.log(message)
        }

        if (props.client.isConnected()){
            props.client.subscribe("lamplight", updateLamp);
            props.client.subscribe("mainlight", updateMain)
            
        }
    
        return function cleanup() {
            
            props.client.unsubscribe("lamplight");
            props.client.unsubscribe("mainlight");
        }

    },[])

    return (
        <React.Fragment>
            
            
            <PageLinkGroup>
                <PageLink onClick={mainIsOn ? turnOffMain : turnOnMain}>
                    {mainIsOn ? "Main Lights Off" : "Main Lights On"}
                </PageLink>
                <PageLink onClick={lampIsOn ? turnOffLamp : turnOnLamp}>
                    {lampIsOn ? "Lamp Lights Off" : "Lamp Lights On"}
                </PageLink>
                <PageLink onClick={computerOff}>
                    "Computer Off"
                </PageLink>
                <PageLink to="/iot" >
                    Return
                </PageLink>
            </PageLinkGroup>
            
        </React.Fragment>
        
    )
}