import React, {useState, useEffect } from 'react';
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import PageLink from './PageLink';
import PageLinkGroup from './PageLinkGroup';

export default function PianoControl(props) {
    const [isRandom, setIsRandom] = useState(false);
    const [isOn, setIsOn] = useState(false);
    const [color, setColor] = useColor("hex", "#121212");
    const setColorAndSend = function(e) {
        props.client.send("pianocolor", e.hex.substring(1));
        setColor(e)
    }
    const turnOff = function() {
        props.client.send("pianotoggle", "off");
        setIsOn(false)
    }

    const turnOn = function() {
        props.client.send("pianotoggle", "on");
        setIsOn(true)
    }

    const turnOnRandom = function() {
        props.client.send("pianocolor", "random");
        setIsRandom(true)
    }
    const turnOffRandom = function() {
        setColorAndSend(color)
    }

    useEffect(()=>{
        
        var updateOn = function(message) {
            if (message === "on") {
                setIsOn(true)
            } else {
                setIsOn(false)
            }
        }

        var updateOnColor = function(message) {
            if (message === "random") {
                setIsRandom(true)
            } else {
                setIsRandom(false)
                setColor(toColor("hex", "#" + message))
            }
        }

        var updateOnResponse = function(message) {
            var state = message.split(";")[0];
            var pianoColor = message.split(';')[1];
            updateOnColor(pianoColor)
            updateOn(state)
        }

        if (props.client.isConnected()){
            props.client.subscribe("pianocheckresponse", updateOnResponse);
            props.client.subscribe("pianotoggle", updateOn)
            props.client.subscribe("pianocolor", updateOnColor)
            props.client.send("pianocheck", "pianocheck")
        }
    
        return function cleanup() {
            
            props.client.unsubscribe("pianocheckresponse");
            props.client.unsubscribe("pianotoggle");
            props.client.unsubscribe("pianocolor");
        }

    },[])

    return (
        <React.Fragment>
            {!isRandom &&
                <div className="command">
                    <input hidden name="type" value="all" />
                    <input hidden name="color" value={color.hex.substring(1)} />
                    <ColorPicker hideHEX hideRGB  width={Math.min(970, window.innerWidth * 0.97)} height={Math.min(550, window.innerHeight * 0.4)} color={color} onChange={setColorAndSend} dark />
                </div>
            }
            
            <PageLinkGroup>
                <PageLink onClick={isRandom ? turnOffRandom : turnOnRandom}>
                    {isRandom ? "Choose Color" : "Random Colors"}
                </PageLink>
                <PageLink onClick={isOn ? turnOff : turnOn}>
                    {isOn ? "Turn Off" : "Turn On"}
                </PageLink>
                <PageLink to="/iot" >
                    Return
                </PageLink>
            </PageLinkGroup>
            
        </React.Fragment>
        
    )
}